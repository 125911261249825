import { useMutation, useQueryClient } from "react-query";
import {
  genTraceId,
  setToken,
  setQuestions,
  setLatestSubmissionInfo,
  setParamURL,
} from "../../utils/helper";
import {
  InqueryQuestionBody,
  GenerateTokenType,
  SaveQueryParamsType,
} from "./home-type";
import { api } from "../../utils/api";
import { useApplication } from "../../App.context";

export const QUESTIONAIRE_INQUIRY = `survey/questionnaire-inquiry/questions`;
export const SETTING = "setting";
export const GENERATE_TOKEN = "token";
export const GENERATE_TOKEN_IBANK = "partner/auth/token/generate";
export const GENERATE_TOKEN_PT = "pt/auth/token/generate";
export const GENERATE_TOKEN_NEXT = "next/auth/token/generate";

const validateSaveQueryParams = (params: SaveQueryParamsType) => {
  const { authorization_code, ref_id, channel, lang, survey_name } = params;

  if (!authorization_code || authorization_code?.length === 0) {
    return Promise.reject(null);
  }

  if (!survey_name || survey_name?.length === 0) {
    return Promise.reject(null);
  }

  if (
    !channel ||
    channel?.length === 0 ||
    (channel !== "PAOTANG" && channel !== "NEXT" && channel !== "IBANK")
  ) {
    return Promise.reject(null);
  }

  if (channel === "IBANK" && ref_id === "") {
    return Promise.reject(null);
  }

  if (!lang || lang?.length === 0 || (lang !== "TH" && lang !== "EN")) {
    return Promise.reject(null);
  }
};

export const useSaveQueryParams = () => {
  let p;
  const queryClient = useQueryClient();
  return useMutation(
    async (params: SaveQueryParamsType) => {
      setParamURL(params);
      await validateSaveQueryParams(params);
      p = params;
    },
    {
      onSuccess: () => {
        const {
          channel,
          lang,
          survey_name,
          survey_group_id,
          survey_subgroup_id,
        } = p;
        queryClient.setQueryData<SettingType>([SETTING], {
          channel,
          lang,
          survey_name,
          survey_group_id,
          survey_subgroup_id,
        });
      },
    }
  );
};

export const useGetQueryParams = () => {
  const queryClient = useQueryClient();
  let data = queryClient.getQueryData<SettingType>([SETTING]);
  if (!data) {
    data = {
      channel: "",
      lang: "",
      survey_name: "",
      survey_group_id: 0,
      survey_subgroup_id: 0,
    };
  }
  return data;
};

export const useGenerateToken = () => {
  return useMutation(
    async (params: GenerateTokenType) => {
      genTraceId();
      const { channel } = params;
      const path =
        channel === "NEXT"
          ? GENERATE_TOKEN_NEXT
          : channel === "PAOTANG"
          ? GENERATE_TOKEN_PT
          : GENERATE_TOKEN_IBANK;

      if (channel === "NEXT" || channel === "PAOTANG") {
        delete params.ref_id;
      }
      const { data } = await api.gt.post(path, params);
      return data.data;
    },
    {
      onSuccess: (data: any) => {
        setToken(data.token);
      },
    }
  );
};

const getQuestionList = async (url: string) => {
  const { data } = await api.public.get(url);
  return data;
};

export const getThemeUrl = async (url: string) => {
  const { data } = await api.public.get(url);
  return data;
};

export const useGetInqueryQuestion = () => {
  const { setAppTheme } = useApplication();
  return useMutation(async (params: InqueryQuestionBody) => {
    const path = QUESTIONAIRE_INQUIRY;
    const { data } = await api.gt.post(path, params);
    const response: any = data?.data;

    if (data.code === 0 && response.latest_submission_info) {
      setLatestSubmissionInfo(response.latest_submission_info);
    }

    if (data.code === 0 && response.question_url) {
      const resultQuestionList = await getQuestionList(response.question_url);
      setQuestions(resultQuestionList);

      const appTheme = await getThemeUrl(response.theme_config_url);

      if (appTheme) {
        setAppTheme(appTheme);
      }

      return resultQuestionList;
    }
    throw new Error("error");
  });
};
