import Text from "../../../../components/common/Text";
import { useRouter } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";
import { Box } from "@mui/material";
import * as paths from "../../../../constants/paths";
import { ButtonFooter } from "../../../../components/common/Footer";
import { FooterProps } from "../../type";

const Footer = ({ retryButton }: FooterProps) => {
  const { appTheme } = useApplication();
  const { navigate } = useRouter();
  const onClickButton = () => {
    navigate(paths.survey());
  };

  return (
    <Box
      sx={{
        bottom: 0,
        boxSizing: "border-box",
        left: 0,
        paddingBottom: "40px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "0px",
        width: "100%",
        position: "fixed",
      }}
    >
      <ButtonFooter
        onClick={onClickButton}
        talk={retryButton}
        backgroundColor={appTheme?.default.button.background_color}
      >
        <Text color="white" type="Bold" size="16px" lineHeight="24px">
          {retryButton}
        </Text>
      </ButtonFooter>
    </Box>
  );
};

export default Footer;
