import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/th";
import {
  useGenerateToken,
  useSaveQueryParams,
  useGetInqueryQuestion,
} from "../../services/home/home-query";
import {
  getToken,
  useRouter,
  getLatestSubmissionInfo,
  getQuestions,
  getFieldLang,
} from "../../utils/helper";
import { Container, ContainerLoading } from "./style";
import { SaveQueryParamsType } from "../../services/home/home-type";
import * as paths from "../../constants/paths";
import { useApplication } from "../../App.context";

import humps from "humps";
import Loading from "../../components/common/Loading";

const Home = () => {
  const { query, navigate } = useRouter();
  const { mutate: saveQueryParams } = useSaveQueryParams();
  const { mutate: genToken } = useGenerateToken();
  const {
    data: responseQuestion,
    mutate: getInqueryQuestion,
    isLoading,
  } = useGetInqueryQuestion();
  const { appTheme, setisLoading } = useApplication();
  const {
    authorization_code,
    ref_id,
    channel,
    lang,
    survey_name,
    survey_group_id,
    survey_subgroup_id,
  }: any = humps.decamelizeKeys(query);

  const token = getToken();

  useEffect(() => {
    document.documentElement.lang = lang.toLowerCase();
  }, [lang]);

  useEffect(() => {
    const params: SaveQueryParamsType = {
      authorization_code: authorization_code || "",
      ref_id: ref_id || "",
      survey_group_id: Number(survey_group_id) || 0,
      survey_subgroup_id: Number(survey_subgroup_id) || 0,
      channel: channel.toString().toUpperCase() || "",
      lang: lang || "",
      survey_name: survey_name || "",
    };

    saveQueryParams(params);
  }, [saveQueryParams, query]);

  useEffect(() => {
    genToken({
      channel: channel?.toString().toUpperCase() || "",
      ref_id: ref_id || "",
      authorization_code: authorization_code || "",
    });
  }, [genToken, query]);

  useEffect(() => {
    if (token && token.length > 0) {
      getInqueryQuestion({
        survey_group_id: Number(survey_group_id) || 0,
        survey_subgroup_id: Number(survey_subgroup_id) || 0,
        survey_name: survey_name || "",
      });
      setisLoading(isLoading);
    }
  }, [token, getInqueryQuestion]);

  useEffect(() => {
    if (responseQuestion) {
      setisLoading(isLoading);
      const introInfo = getQuestions();
      const latestSubmissionInfo = getLatestSubmissionInfo();
      if (!latestSubmissionInfo) {
        return navigate(paths.survey());
      }
      const payload = {
        title: getFieldLang(
          introInfo.dynamic_label.introduction,
          "title",
          lang ? lang.toLowerCase() : "th"
        ),
        latestSubmissionDatetimeLabel:
          latestSubmissionInfo.latest_submission_datetime_label || `NO_DATA`,
        latestSubmissionDatetime:
          latestSubmissionInfo.latest_submission_datetime || `NO_DATA`,
        testResultLabel: latestSubmissionInfo.result_label || `NO_DATA`,
        testResult: latestSubmissionInfo.result || `NO_DATA`,
        subTitle: getFieldLang(
          introInfo.dynamic_label.introduction,
          "text1",
          lang ? lang.toLowerCase() : "th"
        ),
        retryButton: getFieldLang(
          introInfo.dynamic_label,
          "start_survey_text",
          lang ? lang.toLowerCase() : "th"
        ),
      };
      return navigate(paths.introduction(), { state: payload });
    }
  }, [responseQuestion]);

  return <Loading />;
};
export default Home;
