import { Divider, Typography } from "@mui/material";
import FlexCard from "../../../../components/common/FlexCard";
import { SubContainer } from "../../style";
import {
  Container,
  EmptySpace,
  StyledImage,
  StyledLayoutText,
  StyledLayoutTextContent,
  StyledLayoutTextResult,
  StyledLayoutTextFooter,
  DangerousDiv,
} from "./style";
import { GRAY_5A6E7B } from "../../../../constants/colors";
import { useApplication } from "../../../../App.context";
import Footer from "../Footer";
import { ContentProps } from "../../type";
import { useState } from "react";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import ReactDOMServer from "react-dom/server";
import { formatDatetime } from "../../../../utils/helper";
import Text from "../../../../components/common/Text";

const Content = ({
  title,
  latestSubmissionDatetimeLabel,
  latestSubmissionDatetime,
  testResultLabel,
  testResult,
  subTitle,
  retryButton,
}: ContentProps) => {
  const { appTheme } = useApplication();
  const [fallbackImage, setFallbackImage] = useState(true);

  const imagePlaceholder = ReactDOMServer.renderToString(
    <ImagePlaceholder
      bg={appTheme?.questionnaire.placeholder_color_1}
      fg={appTheme?.questionnaire.placeholder_color_2}
    />
  );

  return (
    <Container>
      <SubContainer>
        <FlexCard
          topImage={
            <StyledImage
              fallbackImage={fallbackImage}
              draggable="false"
              src={
                appTheme?.questionnaire.intro_image_url
                  ? appTheme?.questionnaire.intro_image_url
                  : ""
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `data:image/svg+xml,${encodeURIComponent(
                  imagePlaceholder
                )}`;
                setFallbackImage(false);
              }}
              alt="Introduction Image"
            />
          }
        >
          <StyledLayoutText>
            <Text size="20px" lineHeight="30px" type="Bold">
              <DangerousDiv
                dangerouslySetInnerHTML={{ __html: title }}
              ></DangerousDiv>
            </Text>
          </StyledLayoutText>
          <Divider
            sx={{ borderColor: "#5A6E7B1A", height: "1px" }}
            aria-hidden="true"
          />
          <StyledLayoutTextContent>
            <StyledLayoutTextResult>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: GRAY_5A6E7B,
                  fontSize: "16px",
                  lineHeight: "24px",
                  "@media (max-width:280px)": {
                    fontSize: "75%",
                  },
                }}
              >
                {latestSubmissionDatetimeLabel}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: appTheme?.questionnaire.result.color,
                  fontSize: "16px",
                  lineHeight: "24px",
                  "@media (max-width:280px)": {
                    fontSize: "75%",
                  },
                }}
                aria-label={formatDatetime(latestSubmissionDatetime)}
              >
                {latestSubmissionDatetime}
              </Typography>
            </StyledLayoutTextResult>

            <StyledLayoutTextResult>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: GRAY_5A6E7B,
                  fontSize: "16px",
                  lineHeight: "24px",
                  "@media (max-width:280px)": {
                    fontSize: "75%",
                  },
                }}
              >
                {testResultLabel}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  "@media (max-width:280px)": {
                    fontSize: "75%",
                  },
                }}
              >
                {testResult}
              </Typography>
            </StyledLayoutTextResult>
          </StyledLayoutTextContent>
        </FlexCard>
        <StyledLayoutTextFooter>
          <>
            <Typography
              sx={{
                fontWeight: 400,
                color: GRAY_5A6E7B,
                fontSize: "14px",
                lineHeight: "21px",
              }}
            >
              <DangerousDiv
                dangerouslySetInnerHTML={{ __html: subTitle }}
              ></DangerousDiv>
            </Typography>
          </>
        </StyledLayoutTextFooter>
        <EmptySpace></EmptySpace>
        <Footer retryButton={retryButton} />
      </SubContainer>
    </Container>
  );
};

export default Content;
