import Text from "../../../../components/common/Text";
import FlexCard from "../../../../components/common/FlexCard";
import { SubContainer } from "../../style";
import {
  Container,
  EmptySpace,
  StyledImage,
  StyledLayoutText,
  StyledLayoutTextContent,
  StyledLayoutTextQuestion,
  StyledLayoutTextResult,
  StyledSkeletonResult,
} from "./style";
import ImageSuccessResult from "../../../../constants/images/success_icon.svg";
import {
  GRAY_5A6E7B,
  GRAY_F9FAFB,
  PRIMARY_007BC4,
  RED_FFF0F0,
  BLACK_000000,
} from "../../../../constants/colors";
import ResultText from "../../../../components/common/ResultText";
import Footer from "../Footer";
import Skeleton from "@mui/material/Skeleton";
import {
  getFieldLang,
  getLang,
  getQuestions,
  getResult,
  getToken,
  useRouter,
} from "../../../../utils/helper";
import {
  Answer,
  AnswerOption,
  AnswerQuestion,
  NewQuestionResult,
  QuestionResult,
  SuccessResult,
} from "../type";
import questionApi from "../../../../mock/result.json";
import { useEffect, useMemo, useState } from "react";
import mockQuestionResult from "../../../../mock/mockQuestionResul.json";
import { useApplication } from "../../../../App.context";
import errorImageDefault from "../../../../constants/images/image_bot_default.svg";
import { useGetInquiryAnswer } from "../../../../services/success/success-query";
import ReactDOMServer from "react-dom/server";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import { render } from "@testing-library/react";
import SkeletonUI from "./SkeletonUI";

const Content = () => {
  const { appTheme } = useApplication();
  const [successResult, setSuccessResult] = useState<SuccessResult[]>(
    [] as SuccessResult[]
  );
  const [Loading, setLoading] = useState(true);
  const [fallbackImage, setFallbackImage] = useState(true);
  const {
    isLoading,
    isSuccess,
    data: responseAnswer,
    mutate: getInquiryAnswer,
  } = useGetInquiryAnswer();
  const [totalCorrect, setTotalCorrect] = useState<number>(0);
  const token = getToken();
  const questionList = getQuestions();
  const answerResult = getResult();
  const lang = getLang().toLowerCase();

  const imagePlaceholder = ReactDOMServer.renderToString(
    <ImagePlaceholder
      bg={appTheme?.questionnaire.placeholder_color_1}
      fg={appTheme?.questionnaire.placeholder_color_2}
    />
  );

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (token && token.length > 0) {
      getInquiryAnswer({
        survey_group_id: Number(questionList?.survey_group_id) || 0,
        survey_subgroup_id: Number(questionList?.survey_subgroup_id) || 0,
      });
    }
  }, [token, getInquiryAnswer]);

  useEffect(() => {
    if (responseAnswer) {
      updateResultAnswers(responseAnswer);
    }
  }, [responseAnswer]);

  const updateResultAnswers = (answer) => {
    const answerQuestion: AnswerQuestion[] = answer.questions;
    const dataSuccessResult: SuccessResult[] = [];
    let correctCount: number = 0;
    const result: NewQuestionResult = answerResult;
    const resultSorted = [...result.answers].sort((a, b) => a.qId - b.qId);
    resultSorted.forEach((value, index) => {
      const questionResult = answerQuestion.find(
        (item) => item.id === value.qId
      );
      const currentQuestion = questionList.questions.find(
        (item) => item.id === value.qId
      );

      if (questionResult && currentQuestion) {
        const optionResult = questionResult.options.find(
          (item) => item.id === value.optId
        );

        const currentAnswer = currentQuestion.options.find(
          (item) => item.id === value.optId
        );

        if (optionResult) {
          dataSuccessResult.push({
            question: getFieldLang(
              questionList.questions[index],
              "title",
              lang
            ),
            question_voiceover: getFieldLang(
              questionList.questions[index],
              "title_voiceover",
              lang
            ),
            answer: getFieldLang(currentAnswer, "label", lang),
            answer_voiceover: getFieldLang(
              currentAnswer,
              "label_voiceover",
              lang
            ),
            correct: optionResult.is_correct,
            result_description: getFieldLang(
              optionResult,
              "result_description",
              lang
            ),
            result_description_voiceover: getFieldLang(
              optionResult,
              "result_description_voiceover",
              lang
            ),
            correct_message: isAnswerCorrect(optionResult.is_correct, lang),
          });
          if (optionResult.is_correct) {
            correctCount++;
          }
        }
      }
    });
    setTotalCorrect(correctCount);
    setSuccessResult(dataSuccessResult);
  };

  const isAnswerCorrect = (isCorrect: boolean, lang: string) => {
    if (isCorrect) {
      switch (lang) {
        case "th":
          return "ถูกต้อง";

        case "en":
          return "Correct";

        default:
          return "ถูกต้อง";
      }
    } else {
      switch (lang) {
        case "th":
          return "ไม่ถูกต้อง";

        case "en":
          return "Incorrect";

        default:
          return "ถูกต้อง";
      }
    }
  };

  const renderCorrectAmount = (
    totalCorrect: number,
    length: number,
    lang: string
  ) => {
    switch (lang) {
      case "th":
        return `ถูก ${totalCorrect} จาก ${length} ข้อ`;

      case "en":
        return `${totalCorrect} correct out of ${length}`;

      default:
        return `ถูก ${totalCorrect} จาก ${length} ข้อ`;
    }
  };

  return (
    <Container>
      {Loading ? (
        <SkeletonUI />
      ) : (
        <SubContainer backgroundColor={appTheme?.default.background.color}>
          <FlexCard
            bgColor={GRAY_F9FAFB}
            topImage={
              <StyledImage
                fallbackImage={fallbackImage}
                role="img"
                draggable="false"
                type={"image/svg+xml"}
                aria-label="Success Image"
                data={
                  appTheme?.questionnaire.result_image_url
                    ? appTheme?.questionnaire.result_image_url
                    : ""
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.data = `data:image/svg+xml,${encodeURIComponent(
                    imagePlaceholder
                  )}`;
                  setFallbackImage(false);
                }}
              >
                Success Image
              </StyledImage>
            }
          >
            <StyledLayoutText style={{ gap: "0.5rem" }}>
              <Text
                type="Bold"
                size="20px"
                lineHeight="30px"
                talk={getFieldLang(
                  questionList.dynamic_label.result,
                  "text1",
                  lang
                )}
                textAlign="center"
              >
                {getFieldLang(questionList.dynamic_label.result, "text1", lang)}
              </Text>
              <Text
                type="Bold"
                weight={700}
                color={appTheme?.questionnaire.result.color}
                size="24px"
                lineHeight="35px"
                talk={renderCorrectAmount(
                  totalCorrect,
                  successResult.length,
                  lang
                )}
              >
                {renderCorrectAmount(totalCorrect, successResult.length, lang)}
              </Text>
            </StyledLayoutText>
            {successResult.map((item, index) => (
              <div key={index}>
                <FlexCard
                  marginY={1}
                  boxShadow="0px 2px 4px 0px rgba(188, 204, 214, 0.40)"
                >
                  <StyledLayoutTextContent>
                    <StyledLayoutTextQuestion>
                      <Text
                        type="Bold"
                        size="16px"
                        lineHeight="24px"
                        talk={`${index + 1}. ${item.question_voiceover}`}
                      >
                        {`${index + 1}. ${item.question}`}
                      </Text>
                    </StyledLayoutTextQuestion>
                  </StyledLayoutTextContent>
                  <StyledLayoutTextContent>
                    <StyledLayoutTextResult>
                      <Text
                        weight={400}
                        color={GRAY_5A6E7B}
                        size="16px"
                        lineHeight="24px"
                      >
                        {getFieldLang(
                          questionList.dynamic_label.result,
                          "submit_answer_text",
                          lang
                        )}
                      </Text>
                      <Text
                        weight={700}
                        color={GRAY_5A6E7B}
                        size="16px"
                        lineHeight="24px"
                        talk={item.answer_voiceover}
                      >
                        {item.answer}
                      </Text>
                    </StyledLayoutTextResult>

                    <StyledLayoutTextResult>
                      <Text
                        weight={400}
                        color={GRAY_5A6E7B}
                        size="16px"
                        lineHeight="24px"
                      >
                        {getFieldLang(
                          questionList.dynamic_label.result,
                          "answer_text",
                          lang
                        )}
                      </Text>
                      <ResultText
                        isCorrect={item.correct}
                        text={item.correct_message}
                      />
                    </StyledLayoutTextResult>

                    {!item.correct && (
                      <FlexCard bgColor={RED_FFF0F0}>
                        <Text
                          weight={400}
                          color={BLACK_000000}
                          size="14px"
                          lineHeight="21px"
                          textAlign="left"
                          talk={item.result_description_voiceover}
                        >
                          {item.result_description}
                        </Text>
                      </FlexCard>
                    )}
                  </StyledLayoutTextContent>
                </FlexCard>
              </div>
            ))}
            <div style={{ marginTop: "32px" }}>
              <Footer isLoading={Loading} />
            </div>
            <EmptySpace></EmptySpace>
          </FlexCard>
        </SubContainer>
      )}
    </Container>
  );
};

export default Content;
