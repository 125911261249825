import { useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import { difference } from "lodash";
import { QuestionCardType } from "../../components/QuestionCard/type";
import { FormValues } from "./type";
import Footer from "./Components/Footer";
import Content from "./Components/Content";
import { StyleForm } from "./style";
import { useSubmitQuestionBOT } from "../../services/bot/bot-query";
import { useGetQueryParams } from "../../services/home/home-query";
import { SubmitQuestionBOTType } from "../../services/bot/bot-type";
import {
  useRouter,
  setResult,
  getQuestions,
  getParamURL,
} from "../../utils/helper";
import { useApplication } from "../../App.context";

const Survey = () => {
  const { query } = useRouter();
  const dataQueryParams = useGetQueryParams();
  const data = getQuestions();
  const { channel, lang } = getParamURL();
  const { isLoading } = useApplication();
  const { mutate: submitQuestionBOT } = useSubmitQuestionBOT();
  const onSubmit = useCallback(
    (value: FormValues) => {
      const newValue = Object.entries(value.questions).map(([key, value]) => {
        const surveyQuestionId = Number(key.replace("question", ""));
        const optionId = JSON.parse(value).id;
        return { qId: surveyQuestionId, optId: optionId };
      });
      const params: SubmitQuestionBOTType = {
        survey_group_id: data?.survey_group_id,
        channel: channel,
        lang: lang,
        answers: newValue,
        tempChannel: dataQueryParams?.channel,
      };

      setResult(params);
      submitQuestionBOT(params);
    },
    [query, data, dataQueryParams, submitQuestionBOT]
  );

  const validateForm = useCallback(
    (value: FormValues) => {
      const { questions } = value;
      const errors = { questions: {} };

      const newQuestion = (questions && Object.entries(questions)) || [];

      if (newQuestion.length !== data?.questions.length) {
        const x = newQuestion.map(([key]) => key);

        const errorNumber = difference(
          data?.questions.map((q: QuestionCardType) => `question${q.id}`),
          x
        );

        for (const n of errorNumber) {
          errors["questions"][n] = "";
        }
      }

      return errors;
    },
    [data?.questions]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Form<FormValues> onSubmit={onSubmit} validate={validateForm}>
      {({ handleSubmit }) => {
        return (
          <StyleForm onSubmit={handleSubmit}>
            <Content isLoading={isLoading} />
            <Footer onClickButton={handleSubmit} isSkeleton={isLoading} />
          </StyleForm>
        );
      }}
    </Form>
  );
};

export default Survey;
